import React from 'react'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'

const Values = ({ location }) => (
  <BasicLayout>
    <SEOMeta title="Qualdesk company values" path={location.pathname} />
    <article className="mx-auto p-6 max-w-3xl lg:max-w-4xl">
      <header className="md:shadow-solid md:p-8 md:bg-white md:border md:border-border-weak-light md:rounded-2xl lg:mt-6 lg:p-10">
        <div className="mx-auto max-w-xl lg:max-w-2xl">
          <h1 className="text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight sm:text-4xl lg:text-5xl">
            Our values
          </h1>
        </div>
      </header>
      <div className="mt-10 mx-auto max-w-xl text-text-primary-light text-sm md:mt-16 lg:max-w-2xl">
        <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
          Qualdesk is still a young company. Our values and culture are still
          evolving, and what is true today may not be true in the future. We
          encourage every member of our team to help us shape what we believe
          and the way we work.
        </p>
        <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
          Here are seven things that are important to us now:
        </p>
        <div className="flex flex-col space-y-12 my-12">
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We are people-focused
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We build our products for the people who use them; they’re a
              source of great ideas, and a community we rely on to guide our
              decision-making processes. We don’t build technology for
              technology’s sake.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We are ambitious
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We have a big vision and want to realise it wholeheartedly. We
              challenge ourselves to think bigger and to do more.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We are positive by default
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We assume that everyone always does their best. Our approach to
              leadership is about giving people the support they need, and then
              getting out of the way.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We value progress over perfection
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We move decisively, not just quickly. We’re open with each other
              so that we can learn from our mistakes and keep moving. We know
              that it’s okay to be embarrassed with what we’ve built today
              because we can always improve tomorrow. Failing is the best
              opportunity to learn.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We are asynchronous by default
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We write things down. We are respectful of people’s time and don’t
              demand that they spend time with us when it isn’t needed. Equally,
              we know when scheduling a meeting is the best way forward and we
              get better at making those impactful. This helps us work more
              flexibly and allows people to work together across cities,
              countries and time zones.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We know that diversity builds better everything
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We’re building products for the whole world. As we grow, the
              diversity of experience, origin, and culture within our team will
              have a direct impact on our ability to build the best products for
              our users. And the most enjoyable place to work.
            </p>
          </div>
          <div>
            <h5 className="first:mt-0 mt-8 text-base font-extrabold tracking-tight leading-tight sm:text-lg lg:text-xl">
              We build our workplace like we build our products
            </h5>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl first:text-lg sm:first:text-xl lg:first:text-2xl">
              We use the same iterative, adaptive, evidence-based and
              people-centric approach to determine both what we build and how we
              work together.
            </p>
          </div>
        </div>
      </div>
    </article>
  </BasicLayout>
)

export default Values
